@import url('https://fonts.googleapis.com/css?family=Roboto');

html, body {
  font-family: 'Roboto', sans-serif;
}

.navbar-header {
  .navbar-brand {
    img {
      height: 100%;
    }
  }
}

.panel-mss {
  .panel-heading {
    font-size: 20px;
    background: #158ac5;
    color: #fff;
  }
}

.label.mss_monitoring {
  background-color: green;
}

.label.mss_security {
  background-color: blue;
}

.block-debt {
  color: #ff0000;
}

.block-empty-account {
  color: #ff0000;
}

.sms-balance {
  padding: 15px 30px 0 0;

  span {
    font-size: 12px;
    color: #999;
  }

  b {
    color: green;
  }
}

.sms-balance-danger {
  b {
    color: red;
  }
}

.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%;
}

.table-vertical-middle {
  td, th {
    vertical-align: middle !important;
  }
}

.tr-not-active {
  color: #999;

  a {
    color: #999;
  }

  .block-debt {
    color: #999;
  }

  .block-empty-account {
    color: #999;
  }
}

.checkbox-inline {
  label {
    font-weight: normal;
  }
}

.sectionHeadOne {
  padding: 0 0 20px;

  .section-block {
    padding: 23px 0 0 40px;
  }

  h1 {
    .label {
      font-size: 20px;
    }
  }

  .head-one-button {
    padding-top: 23px;
  }
}

.sectionHeadOne::after {
  content: '';
  clear: both;
  display: block;
}

.sectionHeadTwo {
  padding: 0 0 20px;

  .section-block {
    padding: 20px 0 0 30px;
  }
}

.sectionHeadTwo::after {
  content: '';
  clear: both;
  display: block;
}

.alert-summary {
  .summary-label {
    font-size: 12px;
  }

  .summary-value {
    font-weight: bold;
    font-size: 16px;
  }
}

.sectionChargesList {
  .tr-debt {
    color: #ff0000;

    a {
      color: #ff0000;
    }
  }

  .tr-scheduled {
    color: #999;

    a {
      color: #999;
    }
  }
}

.table-venues {
  .td-rate {
    .rate-services {
      padding: 6px 0 0 10px;

      div {
        font-size: 11px;
        color: #999;
      }
    }
  }
}

.form-login {
  .logo-block {
    padding: 50px 0 30px 0;

    img {
      height: 80px;
    }
  }

  .form-group-submit {
    padding-top: 20px;
  }
}

.table-crm-payments {
  .td-time {
    small {
      color: #999;
    }
  }

  .td-sum {
    small {
      display: block;
      text-decoration: line-through;
      color: #999;
    }
  }
}

.ticket-messages {
  .message-gray {
    background: #eee;
  }

  .message-text {
    padding: 15px 0 0;
  }

  .message-attachments {
    padding: 15px 0 0;
  }
}

.ticket-message-compose {
  background: #f0f0f0;
  padding: 20px 20px 10px;
  border-radius: 5px;
  margin: 0 0 30px;
}